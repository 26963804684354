@font-face {
    font-family: "Commissioner";
    src: url("commissioner-subsetted.woff2");
    font-weight: 100 900;
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00FF, U+0131,
        U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2007-200B, U+2010,
        U+2012-2015, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030,
        U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212,
        U+2215;
}

/* Set custom properties for each layout feature */
:root {
    --commissioner-aalt: "aalt" off;
    --commissioner-case: "case" off;
    --commissioner-dnom: "dnom" off;
    --commissioner-frac: "frac" off;
    --commissioner-numr: "numr" off;
    --commissioner-ordn: "ordn" off;
    --commissioner-sinf: "sinf" off;
    --commissioner-subs: "subs" off;
    --commissioner-sups: "sups" off;
}

/* If class is applied, update custom property and
   apply modern font-variant-* when supported */
.commissioner-aalt {
    --commissioner-aalt: "aalt" on;
}

.commissioner-case {
    --commissioner-case: "case" on;
}

.commissioner-dnom {
    --commissioner-dnom: "dnom" on;
}

.commissioner-frac {
    --commissioner-frac: "frac" on;
}

@supports (font-variant-numeric: diagonal-fractions) {
    .commissioner-frac {
        --commissioner-frac: "____";
        font-variant-numeric: diagonal-fractions;
    }
}

.commissioner-numr {
    --commissioner-numr: "numr" on;
}

.commissioner-ordn {
    --commissioner-ordn: "ordn" on;
}

@supports (font-variant-numeric: ordinal) {
    .commissioner-ordn {
        --commissioner-ordn: "____";
        font-variant-numeric: ordinal;
    }
}

.commissioner-sinf {
    --commissioner-sinf: "sinf" on;
}

.commissioner-subs {
    --commissioner-subs: "subs" on;
}

@supports (font-variant-position: sub) {
    .commissioner-subs {
        --commissioner-subs: "____";
        font-variant-position: sub;
    }
}

.commissioner-sups {
    --commissioner-sups: "sups" on;
}

@supports (font-variant-position: super) {
    .commissioner-sups {
        --commissioner-sups: "____";
        font-variant-position: super;
    }
}

/* Apply current state of all custom properties
   whenever a class is being applied */
.commissioner-aalt,
.commissioner-case,
.commissioner-dnom,
.commissioner-frac,
.commissioner-numr,
.commissioner-ordn,
.commissioner-sinf,
.commissioner-subs,
.commissioner-sups {
    font-feature-settings: var(--commissioner-aalt), var(--commissioner-case),
        var(--commissioner-dnom), var(--commissioner-frac), var(--commissioner-numr),
        var(--commissioner-ordn), var(--commissioner-sinf), var(--commissioner-subs),
        var(--commissioner-sups);
}

/* Variable instances */
.commissioner {
    font-variation-settings: "wght" 100, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-extralight {
    font-variation-settings: "wght" 200, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-light {
    font-variation-settings: "wght" 300, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-regular {
    font-variation-settings: "wght" 400, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-medium {
    font-variation-settings: "wght" 500, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-semibold {
    font-variation-settings: "wght" 600, "slnt" 0, "FLAR" 0, "VOLM" 100;
}

.commissioner-bold {
    font-variation-settings: "wght" 700, "slnt" 0, "FLAR" 30, "VOLM" 100;
}

.commissioner-extrabold {
    font-variation-settings: "wght" 800, "slnt" 0, "FLAR" 30, "VOLM" 100;
}

.commissioner-black {
    font-variation-settings: "wght" 900, "slnt" 0, "FLAR" 30, "VOLM" 100;
}

.commissioner-italic {
    font-variation-settings: "wght" 100, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-extralight-italic {
    font-variation-settings: "wght" 200, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-light-italic {
    font-variation-settings: "wght" 300, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-italic {
    font-variation-settings: "wght" 400, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-medium-italic {
    font-variation-settings: "wght" 500, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-semibold-italic {
    font-variation-settings: "wght" 600, "slnt" -10, "FLAR" 0, "VOLM" 100;
}

.commissioner-bold-italic {
    font-variation-settings: "wght" 700, "slnt" -10, "FLAR" 30, "VOLM" 100;
}

.commissioner-extrabold-italic {
    font-variation-settings: "wght" 800, "slnt" -10, "FLAR" 30, "VOLM" 100;
}

.commissioner-black-italic {
    font-variation-settings: "wght" 900, "slnt" -10, "FLAR" 30, "VOLM" 100;
}

