.header {
  position: relative;
  margin: 0 auto;
  max-width: 80em;
  padding-bottom: 0;
  width: 100%;
  font-family: "Commissioner",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
  font-feature-settings: "kern" 1;
  font-size: 1rem;
  color: #191917;
}

.header-nav {
  background-image: url(https://i.gse.io/frontend/header/siteheader-swash.jpg);
  background-position: left -20px top -18px;
  background-size: 236px 86px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 80em;
  padding: 12px 16px 20px;
  position: relative;
  width: 100%;
}

.h-nav-primary {
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 4px;
  justify-content: flex-start;
  gap: 8px;
}

.h-nav-goldstar {
  align-items: flex-start;
  transition: opacity 250ms ease-in-out,visibility 250ms ease-in-out;
  background-size: auto;
  background-position: left center;
  height: 30px;
  width: 135px;
  overflow: hidden;
  background-image: url(https://i.gse.io/new_images/branding/tm/goldstar-logo-white.svg);
}

.h-nav-primary a {
  border: 1px solid #f2efe6;
  border-radius: 16px;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #191917;
  background-color: white;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
  white-space: nowrap;
}

@media (max-width: 705px) {
  .header-nav {
    background-position: left -100px top -27px;
    padding: 8px 8px 16px;
  }

  .h-nav-goldstar {
    background-size: auto;
    background-position: left center;
    height: 30px;
    width: 32px;
    overflow: hidden;
  }

  .h-nav-a.home {
    display: none;
  }
}