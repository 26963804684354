.spin {
  margin: 0px auto;
  color: #444444;
  font-family: 'Commissioner';
}

.spin h1 {
  align-items: baseline;
  font-size: 48px;
  line-height: 1em;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  font-family: 'Commissioner';
  font-weight: 700;
  font-style: normal;
  color: #444;
}

.spin.header-section {
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-image {
  width: 95%;
}

.spin .header-description {
  text-align: center;
  margin-top: 25px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 25px;
  font-weight: 300;
  font-family: 'Commissioner';
}

.spin .page-header {
  margin: 15px auto;
}

.spin .section-name {
  margin-left: 1rem;
}

.spin .tagline {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  margin: 0;
}

.divider {
  border-top: 1px solid rgb(227, 227, 227);
  margin-top: 15px;
  width: 100%;
  margin-bottom: -20px;
}

.spin .top {
  flex-direction: column;
}

/** Elimination Section **/
.event-elimination {
  padding-bottom: 25px;
}

.elimination-section {
  width: 60%;
  margin: 0px auto;
}

.spin .MuiTab-root {
  text-transform: none;
  font-family: 'Commissioner';
  font-size: 18px;
}

.mobile-category-select {
  max-width: 400px;
  margin: 0px auto;
}

.category-events {
  padding: 40px 0px 0px;
  color: white;
  text-align: center;
  font-weight: 300;
  margin: 0px auto;
  margin-bottom: -50px;
}

.icon-text {
  padding-left: 10px;
  padding-bottom: 10px;
}

.category-events h3 {
  font-weight: 300;
}

.spin .spin-options p {
  font-weight: 100;
  font-size: 15px;
}

.spin .event-columns {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 65%;
}

.show-wrapper {
  width: 48%;
}

.spin .event {
  letter-spacing: 1.2px;
  cursor: pointer;
  margin: 10px auto;
  padding: 10px;
  transition: all 300ms ease;
  line-height: 1.5;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #D2D2D2;
  font-family: 'Commissioner';
}

.spin .events p:hover {
  opacity: .7;
}

.spin .disabled .events p:hover {
  opacity: 1;
}

.spin .selected-event .event {
  background-color: #253C50;
  color: white;
}

.spin .eliminated-event .event  {
  text-decoration: line-through;
  background-color: #808080;
  color: #F5F5F5;
}

.spin .previously-seen .event {
  color: #A8A8A8;
  cursor: inherit;
}

.spin .button-wrapper {
  margin: 0px auto;
  display: flex;
  align-items: stretch;
  width: 90%;
}

.spin .event-preference .button-wrapper {
  width: 60%;
}

.spin .event-preference .button-wrapper.special {
  width: 90%;
}

.spin .family-friendly-discount {
  font-size: 13px;
  padding: 10px 0px;
}

.spin .bottom-stack {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin: 0px auto;
}

.spin .page-width-stack {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px auto;
  width: 50vw;
}

/* Events Tooltip */
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  background-color: white;
  color: #444444;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  margin-top: 0px !important;
}

.event-tooltip .event-stack {
  flex-direction: column;
  justify-content: space-between;
}

.event-tooltip .event-details {
  width: 100%;
  padding: 20px;
}

.event-tooltip h4 {
  margin: 10px 0px 20px;
}

.event-tooltip .event-image {
  width: 100%;
  padding: 10px;
  height: auto;
  align-self: center;
}

/* Events Box */
.event-box {
  border-radius: 8px;
  border: 1px #c4c4c4 solid;
  padding: 20px 40px;
  width: 80%;
  margin: 0px auto;
  margin-bottom: 60px;
}

.event-box .event-stack {
  flex-direction: row;
  justify-content: space-between;
}

.event-box .event-image {
  width: 40%;
  height: auto;
  align-self: center;
}

.event-details-section {
  display: block;
}

.event-details-section.hidden {
  display: none;
}

.event-box .event-details {
  width: 60%;
  padding-left: 40px;
}

.event-description {
  font-size: 16px;
  font-weight: 300;
}

.event-venue-name {
  padding-top: 20px;
}

.event-venue-name,
.event-address {
  font-size: 14px;
  font-weight: 400;
}

.event-buttons {
  padding: 10px 0px;
}

.event-button {
  color: white !important;
  text-transform: none !important;
  text-align: center;
  padding: 14px !important;
  font-size: 20px;
  font-weight: 700 !important;
  border: 0px;
  width: 40%;
  margin: 20px auto !important;
  border-radius: 10px;
}

.event-button.selected:hover,
.event-button.selected {
  color: #1D8091 !important;
  border: 2.5px solid #1D8091;
}

/* How it works */
.spin .how-it-works-section {
  border: 3px solid #FEC200;
  border-radius: 10px;
  padding: 30px;
  margin-right: 40px;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 50%;
  justify-content: flex-start;
}

.spin .how-step-stack {
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 34vw;
  margin: 70px auto 40px;
}

.spin .how-image {
  padding: 10px;
  width: 70px;
  height: 70px;
  margin-right: 15px;
}

.spin .how-title {
  font-size: 1.2vw;
  font-weight: 700;
}

.spin .how-desc {
  font-size: 1.1vw;
  font-weight: 300;
}

/* Calendar */
.spin .calendar-stack {
  width: 32vw;
  margin: 0px auto;
  flex-direction: column;
}

.spin .react-calendar {
  border: 1px solid rgb(227, 227, 227);
  padding: 25px;
  border-radius: 10px;
  width: 100%;
  font-family: "Commissioner" !important;
  margin-bottom: 20px;
}

.react-calendar__navigation button,
.react-calendar__navigation button:hover,
.react-calendar__navigation button[disabled] {
  background: white !important;
}

.react-calendar__navigation__arrow:hover {
  opacity: 0.5;
}

.spin .react-calendar__navigation {
  margin: 0px 0px 10px 0px;
}

.spin .react-calendar__month-view {
  margin: 0px auto;
}

.spin .react-calendar__tile {
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 1vw !important;
  font-weight: 200;
  color: #444444;
  border: 2px solid white;
  font-family: "Commissioner" !important;
}

.spin .react-calendar__tile:enabled:hover {
  background-color: #2061a1;
  opacity: 0.8;
  color: white;
}

.spin .react-calendar__tile--active,
.spin .react-calendar__tile--active:enabled:focus {
  background-color: #2061a1;
  color: white;
}

.spin .react-calendar__tile:disabled {
  color: #a8a8a8 !important;
}

.spin .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.spin .react-calendar .react-calendar__month-view__days__day--neighboringMonth abbr,
.spin .react-calendar__tile.sold-out abbr {
  display: none;
}

.spin .react-calendar__tile:disabled {
  -webkit-box-shadow: 0 0 0 0 #fec100 inset;
  -moz-box-shadow: 0 0 0 0 #fec100 inset;
  box-shadow: 0 0 0 0 #fec100 inset !important;
}

.spin .react-calendar__month-view__weekdays__weekday:after {
  content: none;
}

.spin .react-calendar__tile.sold-out:not(.react-calendar__month-view__days__day--neighboringMonth)::after {
  content: "";
  font-size: 13px;
  color: #949494;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0px !important;
}

.spin .react-calendar .react-calendar__month-view__weekdays__weekday:after {
  font-weight: 400;
  display: block;
  line-height: 4px;
  margin: 9px auto;
}

.spin .react-calendar .react-calendar__month-view__weekdays__weekday:first-child:after,
.spin .react-calendar .react-calendar__month-view__weekdays__weekday:nth-child(6):after,
.spin .react-calendar .react-calendar__month-view__weekdays__weekday:last-child:after {
  font-weight: 400;
}

.spin .react-calendar__month-view__days__day--neighboringMonth time {
  display: none;
}

.spin .react-calendar__tile.sold-out {
  padding: 0px !important;
}

.spin .react-calendar__tile.sold-out time {
  display: none;
}

.spin .react-calendar__navigation__label,
.spin .react-calendar__navigation__arrow {
  color: #2061a1;
  font-weight: 700;
  font-size: 26px;
  font-family: "Commissioner" !important;
}

.spin .react-calendar__navigation__prev2-button {
  display: none;
}

.spin .react-calendar__navigation__next2-button {
  display: none;
}

.spin .react-calendar__month-view__weekdays {
  text-align: left;
  font-size: 13px;
  margin-bottom: -8px;
  font-family: "Commissioner" !important;
}

.spin .no-fee-calendar {
  font-size: 13px;
  text-decoration: underline;
  padding: 20px;
}

.spin .no-fee-calendar:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* Add-ons */
.spin .add-ons-section {
  width: 90%;
  margin: 0px auto;
}

.spin .add-ons-stack {
  flex-direction: column;
}

.spin .add-on-item {
  flex-direction: row-reverse;
  padding: 10px;
}

.spin .add-on-button {
  color: white !important;
  font-weight: 700 !important;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  width: 40%;
  height: 50px;
  margin: 10px;
}

.spin .add-on-button.selected {
  color: #1D8091 !important;
  font-weight: 700 !important;
  border: 2.5px solid #1D8091;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.spin .add-on-text {
  width: 60%;
  padding-right: 20px;
}

.spin .add-on-title {
  font-weight: 700;
  padding-bottom: 5px;
}

.spin .add-on-description {
  font-weight: 300;
  font-size: 14px;
}

/* Forms */
.input-fields {
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0px auto;
}

.spin .spin-info {
  letter-spacing: 0px;
  color: #2061a1;
  font-weight: 700;
  padding-top: 5px;
}

.spin .date-quantity {
  padding-top: 10px;
}

.spin .date-time {
  width: 20%;
}

.spin .tickets {
  width: 20%;
  min-width: 100px;
}

.spin .tickets .form-label {
  padding-bottom: 0px;
}

.spin .event-time {
  width: 60%;
}

.spin .event-time .input-field {
  width: 100%;
  height: 50%;
}

.spin .teal-button {
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  width: 25px;
  height: 25px;
  background-color: #1D8091;
}

.spin .teal-button:hover {
  background-color: #358694;
}

.spin .event-time .MuiTypography-body1 {
  text-transform: none;
}

.MuiInputBase-input {
  transform: scale(1, 1.055);
  font-weight: 400;
  padding-top: 30px;
}

.form-label {
  font-weight: 700 !important;
  font-size: 16px;
  color: #444444 !important;
  margin-bottom: 0.375rem;
  margin-top: 0.375rem;
}

.review-title {
  font-weight: 700 !important;
  font-size: 16px;
  color: #444444 !important;
  margin-bottom: 0.375rem;
  margin-top: 0.375rem;
}

.review-item {
  color: #2061a1;
  font-weight: 700 !important;
  font-size: 16px;
  margin-bottom: 0.375rem;
  margin-top: 0.375rem;
}

.Mui-disabled {
  color: #444444 !important;
  -webkit-text-fill-color: #444444 !important;
}

.input-field {
  width: 86%;
}

.input-field.code {
  padding: 5px 0px;
}

.input-field.code .MuiInputBase-input {
  font-size: 12px !important;
  font-weight: 500;
}

.spin .input-field .MuiInput-input {
  transform: scale(1, 1.055);
  font-weight: 500;
  padding-top: 30px;
}

.account-warning {
  transform: scale(1, 1.055);
  font-size: 12px;
  font-weight: 200;
}

.MuiSelect-selectMenu {
  background-color: white !important;
  margin-top: 20px;
}

/* Checkout */
.spin-payment {
  border: 1px #c4c4c4 solid;
  border-radius: 10px;
  width: 100%;
  margin: 0px auto;
}

.review-section {
  width: 15%;
  padding: 20px 10px 20px 40px;
}

.spin .inputs-section {
  width: 50%;
  justify-content: flex-start;
  padding: 20px 10px 20px 40px;
}

.checkout-section {
  width: 50%;
  padding: 20px 40px 0px 10px;
}

.payment-details {
  padding: 20px 0px 0px 0px;
}

.price-item {
  padding: 5px 0px;
}

.spin .grey-button {
  background-color: #BEBEBE;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  margin-left: 10px;
}

.payment-divider-bottom,
.payment-divider-top {
  border-top: 1px solid rgb(227, 227, 227);
  margin: 10px 0px;
}

.payment-form .disclaimer {
  margin: -25px 0px 0px 0px;
}

.payment-form .circular-progress {
  margin: -25px 0px 25px 0px;
}

.field-spacer {
  padding: 5px 0px;
}

.card-inputs-custom {
  width: 100%;
  padding: 40px;
}

.payment-button {
  color: white;
  background-color: #1D8091;
  text-align: center;
  padding: 14px 0px;
  font-size: 20px;
  font-weight: 700;
  border: 0px;
  width: 100%;
  letter-spacing: 3px;
  margin: 20px 0px 40px 0px;
  border-radius: 10px;
}


.payment-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.apple-pay-form .StripeElement {
  border-radius: 10px;
}

.credit-card-form .StripeElement {
  border-radius: 10px;
  border: 1px #c4c4c4 solid;
  padding: 10px;
  background-color: white;
}

.holiday-fee {
  font-size: 14px;
  text-decoration: underline;
}

.holiday-fee:hover {
  opacity: 0.8;
  cursor: pointer;
}

.price-detail {
  text-align: left;
}

.price-number {
  text-align: left;
  width: 100px;
}

.solo-price-number {
  padding-right: 20px;
}

/* Popups */
.popup-content {
  padding: 40px;
  width: 100%;
  text-align: center;
}

.popup-content h2 {
  font-size: 22px;
}

.popup-content h3 {
  font-size: 18px;
  text-transform: none;
}

.popup-content h4 {
  text-transform: none;
}

.popup-content a {
  color: #1D8091 !important;
}

.popup-content li {
  padding-bottom: 10px;
}

.popup-content.ticket-flex p {
  text-align: left;
}

.popup-content .elimination-popup-text {
  font-weight: 500;
  color: #444;
}

.popup-content .questions-paragraph,
.popup-content .restaurant-paragraph,
.popup-content.special-popup {
  text-align: justify;
  text-align-last: center;
}

.popup-content .restaurant-paragraph.safari,
.popup-content.special-popup.safari {
  text-align: center;
}

.popup-buttons {
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.popup-buttons .cta-button {
  margin: 15px auto;
  text-transform: none;
  width: 45%;
  padding: 15px 20px;
}

.popup-buttons .cta-button-no {
  background-color: gray;
}

.popup-buttons .ok-button {
  width: 90%;
}

/** Footer **/
.footer {
  text-align: center;
  margin: 30px;
}

@media (max-width: 1700px) {
  .spin .react-calendar__tile {
    font-size: 1.2vw !important;
  }

  .spin .how-image {
    padding: 10px;
    width: 80px;
    height: 80px;
  }

  .spin .how-number {
    padding: 5px;
    width: 55px;
    height: 55px;
  }

  .spin .how-title {
    font-size: 1.2vw;
  }

  .spin .how-desc {
    font-size: 1.1vw;
  }
}

@media (max-width: 1300px) {
  .spin .how-it-works-section {
    padding: 25px;
  }
}

@media (max-width: 1200px) {
  .spin .how-image {
    padding: 10px;
    width: 60px;
    height: 60px;
  }

  .spin .how-number {
    padding: 5px;
    width: 40px;
    height: 40px;
  }

  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 22px;
  }

  .spin .elimination-section {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 20px;
  }

  .spin .how-it-works-section {
    width: 50%;
  }

  .spin .how-image {
    padding: 5px;
    width: 65px;
    height: 65px;
  }

  .spin .how-number {
    padding: 5px;
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .event-box {
    padding: 20px;
  }

  .spin .event-columns {
    padding: 10px;
  }

  .card-inputs-custom {
    padding: 40px 10px;
  }
}

@media (max-width: 950px) {
  .spin .bottom-stack,
  .spin .page-width-stack {
    flex-direction: column;
  }

  .spin .calendar-stack {
    width: 100%;
    padding: 0px;
    margin-top: 30px;
  }

  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 26px;
  }

  .spin .how-it-works-section {
    width: 100%;
    margin: 0px;
  }

  .spin .how-title {
    font-size: 2vw;
  }

  .spin .how-desc {
    font-size: 1.8vw;
  }

  .spin .input-fields {
    justify-content: space-around;
    width: 100%;
  }

  .spin .react-calendar__tile {
    font-size: 2.5vw !important;
  }

  .spin-payment .content-container {
    display: block;
    padding: 0px;
  }

  .spin .MuiTab-root {
    font-size: 16px;
    margin: 0px auto;
  }

  .spin .category-events {
    padding-bottom: 20px;
  }

  .event-box {
    width: 100%;
    padding: 40px;
  }

  .event-box .event-stack {
    flex-direction: column;
  }

  .event-buttons {
    flex-direction: column;
  }

  .event-box .event-details {
    width: 100%;
    padding-left: 0px;
  }

  .event-box .event-image {
    width: 100%;
  }

  .spin .checkout-section,
  .spin .inputs-section {
    width: 85%;
    margin: 0px auto;
    padding: 20px 0px;
  }

  .payment-divider-bottom,
  .payment-divider-top {
    margin: 10px 0px;
  }

  .input-field,
  .input-field.code {
    width: 100%;
  }

  .popup-content h2 {
    margin-top: 20px;
  }

  .popup-content .cta-button {
    font-size: 14px;
    margin: 5px;
    padding: 5px;
    width: 100%;
  }

  .popup-content .popup-buttons {
    padding: 0px 0px;
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .spin .react-calendar__tile {
    font-size: 3vw !important;
  }

  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 25px;
  }

  .spin .how-step-stack {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    margin: 40px auto 40px;
  }

  .spin .how-image {
    padding: 10px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .spin .how-title {
    font-size: 18px;
  }

  .spin .how-desc {
    font-size: 16px;
  }

  .spin .page-width-stack {
    width: 80%;
  }

  .spin .calendar-stack {
    width: 100%;
    margin: 0px auto;
    flex-direction: column;
  }

  .spin .elimination-section {
    width: 80%;
  }

  .spin .show-wrapper {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .spin .how-it-works-title {
    margin: 5px 0px 10px 0px;
    font-size: 30px;
  }

  .spin .how-image {
    padding: 5px;
    width: 50px;
    height: 50px;
  }

  .spin .how-number {
    padding: 5px;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .spin .icon-text,
  .spin .how-title {
    font-size: 15px;
  }

  .spin .how-desc {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 19px;
  }

  .spin .react-calendar__tile {
    font-size: 3vw !important;
  }

  .spin .add-on-item {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .spin .add-on-header {
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }

  .spin .add-on-button {
    margin: 0px 15px 0px 0px;
    padding: 10px;
    width: 45%;
  }

  .spin .add-on-title {
    padding: 0px;
    width: 55%;
    font-size: 14px;
  }

  .spin .add-on-description {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .spin .events {
    margin: 0px 11px;
  }

  .spin.header-section {
    padding: 0px 30px;
  }

  .spin .page-width-stack {
    width: 98%;
  }

  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 18px;
  }

  .spin .react-calendar__month-view__weekdays {
    font-size: 12px;
  }

  .spin .react-calendar__tile {
    font-size: 3vw !important;
  }

  .spin .input-fields {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .spin .tickets {
    width: 50%;
    margin: 5px 0px;
    font-size: 18px;
  }

  .spin .tickets .form-label {
    padding-bottom: 5px;
  }

  .spin .top-input-fields .spin-info,
  .spin .top-input-fields .form-label {
    text-align: center;
  }

  .spin .input-item {
    padding: 5px;
  }

  .spin .teal-button {
    width: 30px;
    height: 30px;
  }

  .spin .event-time {
    width: 60%;
    margin: 10px 0px;
  }

  .spin .form-label {
    font-size: 15px;
  }

  .solo-price-number,
  .price-number,
  .price-detail {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  .spin .react-calendar__navigation__label, .spin .react-calendar__navigation__arrow {
    font-size: 16px;
  }

  .spin .react-calendar__month-view__weekdays {
    font-size: 10px;
  }
}