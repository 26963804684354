/* Universal element styling */
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  transition: opacity 300ms ease;
  text-decoration: none;
  color: #1D8091;
}

a:hover {
  opacity: .7;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

h1 {
  color: #2061a1;
  letter-spacing: 2px;
  font-weight: 900;
  font-family: 'Commissioner';
  line-height: 90px;
  letter-spacing: 1px;
  text-align: left;
}

h2 {
  font-size: 32px;
  color: #2061a1;
  font-family: 'Commissioner';
  font-weight: 700;
}

h3 {
  font-size: 22px;
  color: #2061a1;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
}

h4 {
  font-size: 22px;
  color: #444444;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 700;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.hidedesktop {
  display: none;
}

.hidemobile {
  display: block;
}

/* Page structure */
.full-width {
  width: 100%;
}

.page-body {
  min-height: 500px;
  background-color: #fec100;
}

.flex-row {
  display: flex;
}

.flex-item {
  flex: 1;
}

.flex-half {
  flex: 0 0 50%;
}

.static-page {
  margin: 0px auto;
  width: 80vw;
  max-width: 1200px;
}

.semibold {
  font-weight: 600;
}

.showmobile {
  display: none !important;
}

/* Background colors */
.pink-background {
  background-color: #fec100;
}

.grey-background {
  background-color: #f5f5f5;
}

.purple-background {
  background-color: #271c21;
}

/* Text styling */
.tiny-text {
  letter-spacing: 1px;
  font-size: 11px;
  font-family: 'Commissioner';
}

.disclaimer {
  font-size: 12px;
  color: black;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 700;
}

.underline {
  border-bottom: 1px solid white;
}

strong {
  font-weight: 500 !important;
}

.italics {
  font-style: italic;
}

.grey-h3 {
  color: #444444;
  font-weight: 400;
}

/* MUI Overrides */
.MuiInputLabel-root,
.MuiInputBase-input,
.MuiOutlinedInput-root,
.MuiMenuItem-root,
.MuiButton-root {
  font-family: 'Commissioner' !important;
}


/* Buttons */
.cta-button {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px;
  font-weight: 500;
  font-family: 'Commissioner';
  font-size: 16px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  background-color: #1D8091;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}

.cta-slim-button {
  max-height: 44px;
  max-width: 100%;
  cursor: pointer;
}

.cta-button:hover,
.cta-slim-button:hover {
  opacity: .9;
}

.cta-button a:hover,
.cta-slim-button a:hover {
  opacity: 1;
}

.large-cta {
  background-color: #271c21;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;
  padding: 14px 0px;
  font-size: 22px;
  border: 0px;
  display: block;
  width: 100%;
  letter-spacing: 3px;
  margin: 20px 0px 40px 0px;
}

.small-cta {
  background-color: #fec100;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-weight: 50;
  padding: 15px 10px;
  font-size: 10px;
  border: 0px;
  display: block;
  letter-spacing: 3px;
}

.small-cta:hover {
  opacity: .8;
  cursor: pointer;
}

.dialog-close {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #939598 !important;
  font-weight: 500;
  cursor: pointer;
}

/* Form */
.MuiInput-input {
  transform: scale(1, 1.055);
  font-weight: 400;
  padding-top: 30px;
}

.MuiFormControlLabel-label {
  text-transform: uppercase;
  transform: scale(1, 1.055);
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.payment-form {
  width: 100%;
}

@media (max-width: 950px) {
  .static-page {
    margin: 0px auto;
    width: 70vw;
  }
}

@media (max-width: 800px) {
  .static-page {
    margin: 0px auto;
    width: 85vw;
  }

  .static-page .container {
    width: 100%;
    margin: 0px auto;
  }

  .showmobile {
    display: inline-block !important;
  }

  .hidemobile {
    display: none;
  }

  h1 {
    font-size: 36px;
    line-height: 42px;
    width: 100%;
    letter-spacing: -1px;
  }

  h2 {
    margin: -30px 0px 50px 0px;
    font-size: 26px;
    width: 100%;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 18px;
  }

  .large-cta {
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .static-page {
    margin: 0px auto;
    width: 90vw;
  }
}