body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, sans-serif;
}

input {
  max-width: 500px;
}

.gray {
  color: #828282;
}

.orange {
  background-color: #ff6600;
}

.background-gray {
  background-color: rgb(246,246,239);
}

.f11 {
  font-size: 11px;
}

.w85 {
  width: 85%;
}

.button {
  font-family: monospace;
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  max-width: 250px;
}

.listItem .visibleOnHover {
  visibility: hidden;
}

.listItem:hover .visibleOnHover {
  visibility: visible;
}

.no-underline {
  text-decoration: none;
}

.no-link-style {
  text-decoration: none;
  color: inherit;

  cursor: pointer;

  transition: opacity 300ms ease;
}

.no-link-style:hover {
  opacity: 0.7;
}

.wistia_placebo_close_button {
  outline: none;
}
